<template>
  <!-- 数字乡村 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">数字乡村</div>
      <div class="title-p">
        数字乡村是伴随网络化、信息化和数字化在农业农村经济社会发展中的应用，以及农民现代信息技能的提高而内生的农业农村现代化发展和转型进程，既是乡村振兴的战略方向，也是建设数字中国的重要内容。
      </div>
    </div>

    <div class="function">
      <div class="function-title">平台架构</div>
      <div class="services">
        <div class="left"></div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">智慧农业</div>
          <div class="sceneContent">
            通过将物联网、大数据、云计算等技术应用到传统农业中，实现了对农业农田项目全周期的可追溯管理，通过农业远程诊断、远程控制等智能管理，极大节省了人力成本，提高了农田管理效率。
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">智慧党建</div>
          <div class="sceneContent">
            以云计算、大数据、互联网为基础，建设全覆盖、全过程、全维度的党建信息化、智慧化的平台，
            实现线上线下相互融合、相互促进的党建工作新格局。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">智慧村务</div>
          <div class="sceneContent">
            智慧村务旨在打破条块分割局面，整合民政、计生、人社等延伸到农村的政务系统和其他各类农村信息资源，实现农村信息资源共享。
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">精准扶贫</div>
          <div class="sceneContent">
            针对不同贫困区域环境、不同贫困农户状况，运用科学有效程序对扶贫对象实施精确识别、精准帮扶、精确管理的治贫方式。
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="function">
      <div class="function-title">功能场景</div>
      <div class="function-title" style="font-size: 32px">智慧农业</div>
      <div class="box1">
        <div class="left">
          <div class="left-title"></div>
          <div class="left-text">
            通过将物联网、大数据、云计算等技术应用到传统农业中，实现了对农业农田项目全周期的可追溯管理，通过农业远程诊断、远程控制等智能管理，极大节省了人力成本，提高了农田管理效率。
          </div>
        </div>
        <div class="right img3"></div>
      </div>
      <div class="function-title" style="font-size: 32px">智慧党建</div>
      <div class="box2">
        <div class="left img4"></div>
        <div class="right">
          <div class="right-title"></div>
          <div class="right-text">
            以云计算、大数据、互联网为基础，建设全覆盖、全过程、全维度的党建信息化、智慧化的平台，
            实现线上线下相互融合、相互促进的党建工作新格局。
          </div>
        </div>
      </div>
      <div class="function-title" style="font-size: 32px">智慧村务</div>
      <div class="box1">
        <div class="left">
          <div class="left-title"></div>
          <div class="left-text">
            智慧村务旨在打破条块分割局面，整合民政、计生、人社等延伸到农村的政务系统和其他各类农村信息资源，实现农村信息资源共享。
          </div>
        </div>
        <div class="right img5"></div>
      </div>
      <div class="function-title" style="font-size: 32px">精准扶贫</div>
      <div class="box2">
        <div class="left img6"></div>
        <div class="right">
          <div class="right-title"></div>
          <div class="right-text">
            针对不同贫困区域环境、不同贫困农户状况，运用科学有效程序对扶贫对象实施精确识别、精准帮扶、精确管理的治贫方式。
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="function">
      <div class="function-title"></div>
    </div> -->
    <div style="margin-top: 50px"></div>
    <div class="scene">
      <h1></h1>
      <div class="img6">
        <div class="sceneBox">
          <div class="sceneIcon"></div>
          <div class="sceneTitle">便民服务</div>
          <div class="sceneContent">
            村民可在手机上进行村情上报、村民招聘就业、家宴预约、乡村咨询等，让村民办事更方便。
          </div>
        </div>
      </div>
      <div class="img7">
        <div class="sceneBox">
          <div class="sceneIcon"></div>
          <div class="sceneTitle">智慧文旅</div>
          <div class="sceneContent">
            利用云计算、物联网、移动通信等多种先进技术，构建景区智慧文旅体系，提高文旅企业的综合管理和运营能力，创建优质的旅游生态环境，提升旅游的服务品质。
          </div>
        </div>
      </div>
      <div class="img8">
        <div class="sceneBox">
          <div class="sceneIcon"></div>
          <div class="sceneTitle">信息发布</div>
          <div class="sceneContent">
            采用LED大屏，可以实施实时查看各类农业信息、村情上报、道路状况等信息。
          </div>
        </div>
      </div>
      <div class="img9">
        <div class="sceneBox">
          <div class="sceneIcon"></div>
          <div class="sceneTitle">监督监管</div>
          <div class="sceneContent">
            履责有载体、监督有平台、建言有渠道、宣传有引导，让党内“一线”监督在基层落地生根。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    // go(i) {
    //   if (i == 1) {
    //     this.$router.push("Agriculture");
    //   }
    //   if (i == 2) {
    //     this.$router.push("partyBuilding");
    //   }
    //   if (i == 3) {
    //     this.$router.push("villageAffairs");
    //   }
    //   if (i == 4) {
    //     this.$router.push("PovertyAlleviation");
    //   }
    // },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script> 

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/ruralImg/bg.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .nh {
    background: url("../../assets/image/parkImg/nhbg.png") no-repeat;
    margin-top: 50px;
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }

    .scenes-box {
      .scenes-bg {
        width: 100%;
        height: 520px;
        background: url("../../assets/image/ruralImg/img6.png") no-repeat;
        background-size: 100% 100%;
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;
      height: 700px;
      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 700px;
        background: url("../../assets/image/ruralImg/组 223.png") no-repeat;
        background-size: 100% 100%;
      }
      //   .right {
      //     width: 30%;
      //     padding: 6%;
      //     box-sizing: border-box;
      //     div {
      //       font-size: 24px;
      //       color: #6b6b6b;
      //     }
      //   }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
    .box1 {
      width: 100%;
      height: 400px;
      display: flex;
      background-color: #fff;
      .left {
        width: 50%;
        padding: 5% 10%;
        box-sizing: border-box;
        .left-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .left-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        // background: url("../../assets/image/buildingImg/img1.png") no-repeat;
      }
    }
    .box2 {
      width: 100%;
      height: 400px;
      display: flex;
      margin-top: 20px;
      background-color: #fff;
      .left {
        width: 50%;
        height: 100%;
        // background: url("../../assets/image/buildingImg/img2.png") no-repeat;
      }
      .right {
        width: 50%;
        padding: 7%;
        box-sizing: border-box;
        .right-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .right-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
    }
    .function-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .function-item {
        width: 18.5%;
        height: 260px;
        // margin-right: 2%;
        background-color: #fff;
        img {
          width: 100px;
          height: 100px;
          margin: 30px 0 0 0;
        }
        div {
          font-size: 20px;
          font-weight: 600;
          margin: 22px 0;
          color: #333333;
        }
        p {
          width: 80%;
          margin: auto;
          font-size: 14px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
    .device {
      width: 100%;
      height: 400px;
      padding: 10%;
      box-sizing: border-box;
      background: url("../../assets/image/buildingImg/bottom.png") no-repeat;
      .device-text {
        font-size: 28px;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}
// .img3 {
//   background: url("../../assets/image/ruralImg/img2.png") no-repeat;
//   background-size: 100% 100%;
// }
// .img4 {
//   background: url("../../assets/image/ruralImg/img3.png") no-repeat;
//   background-size: 100% 100%;
// }
// .img5 {
//   background: url("../../assets/image/ruralImg/img4.png") no-repeat;
//   background-size: 100% 100%;
// }
// .img6 {
//   background: url("../../assets/image/ruralImg/img5.png") no-repeat;
//   background-size: 100% 100%;
// }
.scene {
  padding: 0 10%;
  display: flex;
  margin-bottom: 50px;
  overflow: hidden;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    background: rgba(0, 0, 0, 0.3);
    &:hover {
      background: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 100px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 110px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 180px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
    }
  }
}
.img2 {
  // cursor: pointer;
  background: url("../../assets/image/ruralImg/组 318.png") no-repeat;
  background-size: 100% 100%;
}
.img3 {
  // cursor: pointer;
  background: url("../../assets/image/ruralImg/组 321.png") no-repeat;
  background-size: 100% 100%;
}
.img4 {
  // cursor: pointer;
  background: url("../../assets/image/ruralImg/组 320.png") no-repeat;
  background-size: 100% 100%;
}
.img5 {
  // cursor: pointer;
  background: url("../../assets/image/ruralImg/组 319.png") no-repeat;
  background-size: 100% 100%;
}
.img6 {
  background: url("../../assets/image/ruralImg/蒙版组 59.png") no-repeat;
  background-size: 100% 100%;
}
.img7 {
  background: url("../../assets/image/ruralImg/蒙版组 63.png") no-repeat;
  background-size: 100% 100%;
}
.img8 {
  background: url("../../assets/image/ruralImg/蒙版组 61.png") no-repeat;
  background-size: 100% 100%;
}
.img9 {
  background: url("../../assets/image/ruralImg/蒙版组 62.png") no-repeat;
  background-size: 100% 100%;
}
.icon1 {
  background: url("../../assets/image/ruralImg/icon1.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/ruralImg/icon2.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/ruralImg/icon3.png") no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/ruralImg/icon4.png") no-repeat;
  background-size: 100% 100%;
}
</style>